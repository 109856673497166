import classie from 'classie';

const navbarMenu = document.getElementById('navbar');
const burgerMenu = document.getElementById('burger');
const overlayMenu = document.getElementById('overlay');
const overlay2Menu = document.getElementById('overlay2');
const body = document.getElementsByTagName('body')[0];


// const closeBanner = document.getElementById('closeBanner');
// const banner = document.getElementById('banner');

// Toggle Menu Function
burgerMenu.addEventListener('click', toggleMenu);
overlayMenu.addEventListener('click', toggleMenu);
overlay2Menu.addEventListener('click', toggleMenu);

//function setCookie(cname, cvalue, exdays) {
//  const d = new Date();
//  d.setTime(d.getTime() + (exdays*24*60*60*1000));
//  let expires = "expires="+ d.toUTCString();
//  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
//}
//
//function getCookie(cname) {
//  let name = cname + "=";
//  let decodedCookie = decodeURIComponent(document.cookie);
//  let ca = decodedCookie.split(';');
//  for(let i = 0; i <ca.length; i++) {
//    let c = ca[i];
//    while (c.charAt(0) == ' ') {
//      c = c.substring(1);
//    }
//    if (c.indexOf(name) == 0) {
//      return c.substring(name.length, c.length);
//    }
//  }
//  return "";
//}
//
//function checkCookie() {
//  let bannerCookie = getCookie("banner");
//  if (bannerCookie != "") {
//      banner.classList.remove('hide');
//  } else {
//      
//  }
//}
//
//function closeAnncement() {
//    banner.classList.add('hide');
//    setCookie("banner", "close", 365);
//}


function toggleMenu() {
	navbarMenu.classList.toggle('active');
	overlayMenu.classList.toggle('active');
	body.classList.toggle('nav-open');

}

// Collapse SubMenu Function
// navbarMenu.addEventListener('click', (e) => {
// 	if (e.target.hasAttribute('data-toggle') && window.innerWidth <= 992) {
// 		e.preventDefault();
// 		const menuItemHasChildren = e.target.parentElement;

// 		// If menu-item-child is Expanded, then Collapse It
// 		if (menuItemHasChildren.classList.contains('active')) {
// 			collapseSubMenu();
// 		} else {
// 			// Collapse the Existing Expanded menu-item-child
// 			if (navbarMenu.querySelector('.menu-item-child.active')) {
// 				collapseSubMenu();
// 			}
// 			// Expanded the New menu-item-child
// 			menuItemHasChildren.classList.add('active');
// 			const subMenu = menuItemHasChildren.querySelector('.sub-menu');
// 			subMenu.style.maxHeight = subMenu.scrollHeight + 'px';
// 		}
// 	}
// });

// function collapseSubMenu() {
// 	navbarMenu.querySelector('.menu-item-child.active .sub-menu').removeAttribute('style');
// 	navbarMenu.querySelector('.menu-item-child.active').classList.remove('active');
// }

// Fixed Resize Screen Function
// window.addEventListener('resize', () => {
// 	if (this.innerWidth > 992) {
// 		// If navbarMenu is Open, then Close It
// 		if (navbarMenu.classList.contains('active')) {
// 			toggleMenu();
// 		}

// 		// If menu-item-child is Expanded, then Collapse It
// 		if (navbarMenu.querySelector('.menu-item-child.active')) {
// 			collapseSubMenu();
// 		}
// 	}
// });

// get url segments
var url = window.location.pathname;
var segments = url.split( '/' );
var lastSegment = segments.pop();

// only run on homepage
if (!lastSegment) {

	var cbpAnimatedHeader = (function() {

		var docElem = document.documentElement,
			header = document.querySelector( '.header' ),
			didScroll = false,
			changeHeaderOn = 50;

		function init() {
			window.addEventListener( 'scroll', function( event ) {
				if( !didScroll ) {
					didScroll = true;
					setTimeout( scrollPage, 100 );
				}
			}, false );
		}

		function scrollPage() {
			var sy = scrollY();
			if ( sy >= changeHeaderOn ) {
				classie.add( header, 'header-bar-shrink' );
			}
			else {
				classie.remove( header, 'header-bar-shrink' );
			}
			didScroll = false;
		}

		function scrollY() {
			return window.pageYOffset || docElem.scrollTop;
		}

		
		init();

	})();

}
