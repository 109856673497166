import './components/new-navigation.js';

if (document.getElementById("splide01") ) {
  import("./components/carousel-splide").then(instance => {
      instance.initSplides();
  });
}

if (document.getElementById("splide02") ) {
  import("./components/carousel-splide").then(instance => {
      instance.initSplides02();
  });
}

if (document.getElementById("accordion")) {
  import("./components/accordion").then(instance => {
      instance.initAccordion();
  });
}

if (document.getElementById("iscroll") ) {
  import("./components/iscroller").then(instance => {
      instance.initIscroller();
  });
}


if (document.getElementById("contact") ) {

    const actualBtn = document.getElementById('actual-btn');

    const fileChosen = document.getElementById('file-chosen');

    actualBtn.addEventListener('change', function(){
      fileChosen.textContent = this.files[0].name
    })

}

